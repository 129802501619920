import React /* , { Suspense }  */ from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { LoginForm } from './views/Login/Login'
import '@fontsource/manrope'
import '@fontsource/manrope/400.css'
import '@fontsource/manrope/500.css'
import '@fontsource/manrope/600.css'
import '@fontsource/manrope/700.css'
import { ForgotPassword } from './views/Login/ForgotPassword/ForgotPassword'
import { ErrorBoundary } from './components/ErrorBoundary'
// import Houses from './views/PrivateZone/Houses'
import { SessionProvider } from './components/Context/Session.context'
import { PrivateRoute } from './components/PrivateRoute'
import Pago from './views/Pago'
import BlogRouter from './views/Blog'
import './App.css'
import { AlertsProvider } from './components/Context/Alerts.context'
import { FeatureRoute } from './components/FeatureRoute'
import { featureFlags } from './utils/feature/featureflags'
import { SnackbarProvider } from './components/Context/Snackbar.context'
import { GlobalPositionProvider } from './views/PrivateZone/GlobalDashboard/components/GpContext/Gp.context'
import { LegalAdvice } from './views/Legal/LegalAdvise'
import { Cookies } from './views/Legal/Cookies'
import PremioNavidad from './views/PremioNavidad/PremioNavidad'

const Landing = React.lazy(() => import('./views/Landing/Landing'))
const GlobalDashboard = React.lazy(
  () => import('./views/PrivateZone/GlobalDashboard'),
)
const Houses = React.lazy(() => import('./views/PrivateZone/Houses'))
const Locals = React.lazy(() => import('./views/PrivateZone/Locals'))
const MyArea = React.lazy(() => import('./views/PrivateZone/MyArea'))
const AmortizedCalculator = React.lazy(
  () => import('./views/PrivateZone/Tools/Amortized'),
)
const CalculateHouseProfit = React.lazy(
  () => import('./views/PrivateZone/Tools/CalculateHouseProfit'),
)
const RequestNewTool = React.lazy(
  () => import('./views/PrivateZone/Tools/RequestNewTool'),
)
const SignUpProccess = React.lazy(() => import('./views/Login/SignUpProccess'))
const Securities = React.lazy(() => import('./views/PrivateZone/Securities'))
const PublicTools = React.lazy(() => import('./views/PublicTools'))

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ErrorBoundary>
          <SnackbarProvider>
            <SessionProvider>
              <AlertsProvider>
                <Routes>
                  <Route path="/" element={<Landing />} />
                  <Route path="/premio-navidad" element={<PremioNavidad />} />
                  <Route path="/blog/*" element={<BlogRouter />} />

                  <Route
                    path="/politica-de-privacidad-y-cookies"
                    element={<Cookies />}
                  />
                  <Route path="/aviso-legal" element={<LegalAdvice />} />

                  <Route path="/herramientas/*" element={<PublicTools />} />
                  <Route
                    element={<FeatureRoute feature={featureFlags.signUp} />}
                  >
                    <Route path="/login" element={<LoginForm />} />
                    <Route path="/dar-de-alta/*" element={<SignUpProccess />} />
                    <Route path="/beta/login" element={<LoginForm />} />
                    <Route
                      path="/beta/dar-de-alta/*"
                      element={<SignUpProccess />}
                    />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPassword />}
                    />
                    <Route path="/prueba" element={<Pago />} />
                  </Route>
                  <Route element={<PrivateRoute />}>
                    <Route
                      path="/dashboard"
                      element={
                        <GlobalPositionProvider>
                          <GlobalDashboard />
                        </GlobalPositionProvider>
                      }
                    />
                    <Route path="/mi-area" element={<MyArea />} />
                    <Route path="/valores/*" element={<Securities />} />
                    <Route path="/viviendas/*" element={<Houses />} />
                    <Route path="/locales/*" element={<Locals />} />
                    <Route
                      path="/solicitar-nueva-herramienta"
                      element={<RequestNewTool />}
                    />
                    <Route
                      path="/calculadora-beneficios"
                      element={<CalculateHouseProfit />}
                    />
                    <Route
                      path="/calculadora-amortizacion"
                      element={<AmortizedCalculator />}
                    />
                  </Route>
                </Routes>
              </AlertsProvider>
            </SessionProvider>
          </SnackbarProvider>
        </ErrorBoundary>
      </div>
    </BrowserRouter>
  )
}

export default App
