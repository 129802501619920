import * as React from 'react'
import './style.css'
import { Col, Row, Form } from 'react-bootstrap'
import { useForm, useWatch } from 'react-hook-form'
import { Field } from '../components/Field'

type FormType = { amount: number }

const PremioNavidad: React.FC = () => {
  const [view, setView] = React.useState<
    'description' | 'form' | 'result' | 'premio' | 'masListos'
  >('description')

  const { register, control } = useForm<FormType>({
    mode: 'all',
  })

  const inputwatch = useWatch({ control, name: 'amount' })

  return (
    <>
      <body>
        <div>
          <header>
            <h1>🎄 ¡Participa y Gana un Premio de Navidad 2025! 🎁</h1>

            <p>
              Esta navidad tienes un regalo seguro, solo necesitas completar las
              preguntas del cuestionario para conseguirlo.
            </p>
          </header>
          {view === 'description' && (
            <div className="container pb-4">
              <p className="cta">
                Haz clic abajo para participar en el sorteo:
              </p>

              <button
                type="button"
                className="me-2 mt-4 btn-main"
                onClick={() => setView('form')}
              >
                Empezar encuesta
              </button>
            </div>
          )}
          {view === 'form' && (
            <>
              <form onSubmit={null}>
                <div
                  style={{
                    borderRadius: '8px',
                    padding: '26px',
                  }}
                >
                  <p>Catpcha: Realiza el siguiente cálculo matematico</p>
                  <Form>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Row className="pb-3 mt-2">
                        <Col
                          className="d-flex-column justify-content-center"
                          md={6}
                          sm={6}
                          xs={6}
                        >
                          <p style={{ fontWeight: 'bold' }}> 1 + 1</p>
                        </Col>
                        <Col
                          className="d-flex-column justify-content-center"
                          md={6}
                          sm={6}
                          xs={6}
                        >
                          <Field control={control} name="investmentTime">
                            <Form.Control
                              type="number"
                              {...register('amount', {
                                required: true,
                                min: 0,
                              })}
                            />
                          </Field>
                        </Col>
                      </Row>
                    </Form.Group>
                    <button
                      type="button"
                      className="me-2 mt-4 btn-main"
                      disabled={!inputwatch}
                      onClick={() => {
                        if (parseFloat(inputwatch.toString()) === 3) {
                          setView('masListos')
                        } else {
                          setView('result')
                        }
                      }}
                    >
                      No soy un Robot
                    </button>
                  </Form>
                </div>
              </form>
            </>
          )}
          {view === 'result' && (
            <div className="container pb-4 pt-4">
              <p>
                Lo sentimos, <strong>1 + 1 = 3</strong>, no podeis participar en
                el sorteo pero igualmente os ha tocado el premio.
              </p>
              <button
                type="button"
                className="me-2 mt-4 btn-main"
                onClick={() => setView('premio')}
              >
                Obtener premio
              </button>
            </div>
          )}
          {view === 'masListos' && (
            <div className="container pb-4 pt-4">
              <p>
                Enhorabuena, sois más listos de lo que pensabamos, ¿ya sabeis
                cual es vuestro premio?
              </p>
              <button
                type="button"
                className="me-2 mt-4 btn-main"
                onClick={() => setView('premio')}
              >
                Ver premio
              </button>
            </div>
          )}
          {view === 'premio' && (
            <div className="container pb-4 pt-4">
              <p>
                Felicidades, el premio que os ha tocado es:{' '}
                <strong> SER UNOS ESTUPENDOS ABUELOS</strong>
              </p>
              <p>
                Podeis reclamar vuestro premio a las personas que teneis al lado
              </p>
              <p>
                <strong> Fecha de entrega: </strong> 30 de agosto de 2025 (con
                la fresquita)
              </p>
            </div>
          )}
          <footer>
            <p>
              ¡Que tengas unas felices fiestas! 🎅 |{' '}
              <a href="#">Términos y Condiciones</a>
            </p>
          </footer>
        </div>
      </body>
    </>
  )
}
export default PremioNavidad
